body {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: #a5a5a5;
}

b,
strong {
  font-weight: bold;
}

ul {
  list-style-type: disc;
  list-style-position: inside;
}
ol {
  list-style-type: decimal;
  list-style-position: inside;
}
ul ul,
ol ul {
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 15px;
}
ol ol,
ul ol {
  list-style-type: lower-latin;
  list-style-position: inside;
  margin-left: 15px;
}

.action:hover {
  cursor: pointer;
  opacity: 0.5;
}

.bold {
  font-weight: bold;
}

.green {
  color: #01a200;
}

.red {
  color: red;
}

/*[START EXPERIMENT]: Ambient Desks / @emily-hu / End Date 2024-01-01*/
@font-face {
  font-family: "Bold Pixel";
  src: url("https://cdn.gather.town/storage.googleapis.com/v0/b/gather-town.appspot.com/o/ambient-desks%2Fsmall_bold_pixel-7.ttf?alt=media&token=c5411e05-eb85-4af1-8bd4-8ebbe4fc850c");
}
/*[END EXPERIMENT]: Ambient Desks*/
