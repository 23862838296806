.Layout.center-x {
  left: 50%;
  transform: translateX(-50%);
}

.Layout.center-y {
  top: 50%;
  transform: translateY(-50%);
}

.Layout.center-x.center-y {
  left: 50%;
  transform: translate(-50%, -50%);
}
